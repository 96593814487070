<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: status }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="functionButton" class="vg_ml_8" v-if="isShow">
        <el-button class="vg_ml_8" size="small" type="info" @click="printPreview()" style="margin-right: 10px" v-if="isShow">打印预览 </el-button>
        <el-select
          v-model="financialNotifier"
          class="vg_mr_8"
          clearable
          filterable
          placeholder="请选择财务通知人"
          size="small"
          style="width: 160px"
          v-if="status === 2"
        >
          <el-option v-for="item in financeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button class="vg_ml_8" size="small" type="success" @click="notifyFinance" v-if="status === 2">通知财务 </el-button>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>通知单号：</span>
          <el-input disabled size="small" :value="domescon_no" style="width: 160px"></el-input>
        </div>
      </div>
      <div slot="specialStatus">
        <div class="flexHV">
          <span class="vg_tag_label">状态：</span>
          <el-tag :type="helper.getStatusName(status).type">{{ helper.getStatusName(status).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
    />
    <h3>开票明细</h3>
    <DynamicUTable
      ref="dynamicUTable"
      :tableData="subTableData"
      :columns="domoSconRequisitionSubTableProperties"
      :need-pagination="false"
      :is-show="isShow"
      @selection-change="val => (subSelections = val)"
      :show-summary="true"
      :need-check-box="false"
      :row-click="(row, column, event) => (currentRow = row)"
      :computedData="{ prod_total_price: prod_total_price }"
    >
    </DynamicUTable>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import { domoSconRequisitionMainFormProperties, domoSconRequisitionSubTableProperties } from '@/views/FinanceManagement/DomeSconRequisitionManage/requisition';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { BigNumber } from 'bignumber.js';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { changeStaffForm, downloadFile, getCptt, getStff } from '@api/public';
import { getNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import UrlEncode from '@assets/js/UrlEncode';
import { sconAPI } from '@api/modules/scon';
import { domeSconRequisitionAPI } from '@api/modules/domeSconRequisition';

export default {
  name: 'requisitionEditMain',
  components: {
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      isShow: true,
      btn: {},
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: domoSconRequisitionMainFormProperties
      },
      subTableData: [],
      subSelections: [],
      domoSconRequisitionSubTableProperties: cloneDeep(domoSconRequisitionSubTableProperties),
      domescon_id: '',
      scon_id: '',
      domescon_no: '',
      status: 0,
      cptt_id: '',
      notice_date: '',
      invoice_type: '',
      invoice_no: '',
      invoice_date: '',
      currentRow: {},
      financeList: [],
      financialNotifier: ''
    };
  },
  computed: {
    // 开票金额 = 开票数量 * 开票单价
    prod_total_price() {
      if (!this.currentRow) return;
      let { prod_num, prod_usd } = this.currentRow;
      let temp = new BigNumber(prod_num).times(prod_usd);
      if (!temp.isFinite()) {
        this.currentRow.prod_total_price = 0;
        return '自动计算';
      }
      this.currentRow.prod_total_price = temp.toFixed(4);
      return temp.toFixed(4);
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getRequisitionData();
      await this.changeProperties();
      this.financeList = await getStff('财务', 'valueList');
    },
    async changeProperties() {
      this.mainForm.formProperties.find(({ prop }) => prop === 'cptt_id').options = await getCptt();
      //改为中文单位
      await getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10008 }).then(({ data }) => {
        this.domoSconRequisitionSubTableProperties.find(({ prop }) => prop === 'prod_unit').subItem.options = Array.from(
          data.form.optn_cntt_list,
          ({ param1 }) => {
            return { value: param1, label: param1 };
          }
        );
      });
    },
    //获取数据
    getRequisitionData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      domeSconRequisitionAPI.get_domeScon_by_id({ domescon_id: props.form_id }).then(async ({ data }) => {
        this.btn = data.btn;
        data.form.notice_date = data.form.notice_date ? data.form.notice_date * 1000 : null;
        data.form.scon_cndate = data.form.scon_cndate ? data.form.scon_cndate * 1000 : null;
        data.form.scon_ppdate = data.form.scon_ppdate ? data.form.scon_ppdate * 1000 : null;
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        this.domescon_id = data.form.domescon_id;
        this.domescon_no = data.form.domescon_no;
        this.scon_id = data.form.scon_id;
        this.status = data.form.status;
        changeStaffForm(this.stffForm, data.form);
        this.subTableData = data.form.domeSconProdList;
        setTimeout(() => {
          this.$refs.dynamicUTable2?.reloadTable();
        }, 1000);
      });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      domeSconRequisitionAPI.edit_domeScon(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.domescon_id = this.domescon_id;
      formModel.domescon_no = this.domescon_no;
      formModel.scon_id = this.scon_id;
      formModel.status = this.status;
      formModel.notice_date = formModel.notice_date ? formModel.notice_date / 1000 : null;
      formModel.scon_cndate = formModel.scon_cndate ? formModel.scon_cndate / 1000 : null;
      formModel.scon_ppdate = formModel.scon_ppdate ? formModel.scon_ppdate / 1000 : null;
      formModel.domeSconProdList = this.subTableData;
      return formModel;
    },
    //打印预览
    printPreview() {
      let requestBody = { domescon_id: this.domescon_id, type: 0 };
      domeSconRequisitionAPI.exportInvPdf(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.pdf_url, fileName: data.fileName, type: '.pdf', open: true });
      });
    },
    //通知财务
    notifyFinance() {
      if (!this.financialNotifier) return this.$message.warning('请选择财务通知人');
      sconAPI
        .callFinance({
          stff_id: this.financialNotifier,
          scon_id: this.scon_id,
          domescon_id: this.domescon_id
        })
        .then(({ data }) => {
          this.$message.success('操作成功!');
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
